// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const SecondPage = (props: PageProps) => (
  <Layout>
    <Seo title="Quienes somos" />
    <section className="container mx-auto px-4 mb-4">
      <h1 className="p-6 my-4 heading">Quienes somos</h1>
      <div className="flex flex-col lg:flex-row justify-center">
        <StaticImage
          src="../images/about-us.png"
          alt="Empresa de seguridad"
          className="flex-initial lg:w-2/4"
        />
        <div className="ml-4 flex-initial lg:w-2/4">
          <h2 className="text-xl font-bold mb-2 mt-6 lg:mt-0">GRIGUOL HOY…</h2>
          <p>
            Desde sus inicios, en el año 1989, en Griguol ofrecemos servicios y
            soluciones tecnológicas integrales para control de seguridad en todo
            tipo de propiedades, aportando know how, experiencias y tecnología
            para maximizar los resultados de negocio de nuestros clientes
          </p>
          <p>
            Un claro manejo de la información y la visión estratégica que nos
            caracteriza ha permitido que la compañía sea elegida por más de 400
            organización nacionales y que cuente con más de 5.000 usuarios de
            propiedades horizontales, sanatorios, fábricas y oficinas de
            administración en toda la República Argentina.
          </p>
          <p>
            Griguol está conformada por un equipo humano altamente capacitado,
            utilizando recursos tecnológicos de vanguardia, desarrollan
            productos de muy alta calidad y brindan un servicio de primer nivel
            a todos los clientes.
          </p>
          <h2 className="text-xl font-bold mb-2 mt-6">Nuestra Misión</h2>
          <p>
            Procurar proporcionar oportunidades para el crecimiento y el
            enriquecimiento a nuestros colaboradores, nuestros socios de negocio
            y las comunidades en las cuales funcionamos.
          </p>
          <h2 className="text-xl font-bold mb-2 mt-6">Valores</h2>
          <p>
            Modernización y reinversión tecnológicas. <br/>
            El más exigente nivel de servicios. <br/>
            Pro actividad frente a los requerimientos del Mercado. <br/>
            Relación precio calidad. <br/>
            </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default SecondPage
